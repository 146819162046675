.auth-page {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.auth-page .header {
	background: white;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 14px;
	padding: 8px 32px;
}

.auth-form {
	background: rgb(0 0 0 / 40%);
	width: 420px;
	margin: auto;
	padding: 32px;
	display: flex;
	flex-direction: column;
	border-radius: 16px;
	border-top-color: #00a9f4;
	border-top-style: dashed;
	border-top-width: 12px;

	border-bottom-color: #00a9f4;
	border-bottom-style: dashed;
	border-bottom-width: 12px;
}

.auth-form h1 {
	font-size: 24px;
	color: white;
	margin-bottom: 40px;
}

.auth-form input {
	height: 40px;
	border: none;
	border-radius: 38px;
	padding: 8px 24px;
	margin-bottom: 24px;
	outline: none;
}

.auth-form button {
	height: 40px;
	margin-bottom: 8px;
	background: #03a9f4;
	border: none;
	border-radius: 8px;
}

.auth-form button {
	cursor: pointer;
}
.auth-form button:hover {
	background: rgb(255, 196, 0);
}

.auth-form .login-buttons {
	display: flex;
}

.auth-form .login-buttons button {
	flex: 1;
}
