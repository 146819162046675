* {
	text-decoration: none;
}
body,
html {
	font-family: "Montserrat", sans-serif;
}

body {
	background: #2f2d47;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.flex {
	flex: 1;
}

.row {
	display: flex;
	flex-direction: row;
}

.row.col-2 .input {
	flex: 1;
}

.row.hcenter {
	justify-content: center;
}
.row.hend {
	justify-content: flex-end;
}

.row.vcenter {
	align-items: center;
}
.pd24 {
	padding: 24px;
}

.column {
	display: flex;
	flex-direction: column;
}
