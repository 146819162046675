.follow-us {
	color: #ffffff;
	font-family: Montserrat;
	font-size: 18px;
	line-height: 22px;
	margin-left: 16px;
}

.social{

}
