.hdivider {
	width: 24px;
}

.section {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	justify-content: space-between;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container {
	height: 100vh;
	background: rgba(0, 0, 0, 0.4);
	position: relative;
	padding: 0 64px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
}

.container .content {
	flex: 1 1;
	display: flex;
	flex-direction: column;
}

.container .order-buttons {
	margin-bottom: 130px;
}

.container .bg {
	width: 60%;
	text-align: right;
	top: 20%;
	right: 5%;
}
.container .bg {
	animation: bouge1 4s infinite alternate-reverse;
}

@keyframes bouge1 {
	0% {
		transform: translateY(0px) scale(1);
	}
	50% {
		transform: translateY(20px) scale(0.95);
	}
	100% {
		transform: translateY(0px) scale(1);
	}
}

.container .bg img {
	width: 100%;
	filter: drop-shadow(22px 25px 8px rgba(0, 0, 0, 0.5));
}

.container .logo {
	height: 48px;
	padding: 8px 0;
}
.container .logo img {
	height: 100%;
}

.container .description {
	color: #b6b1f4;
	font-family: Montserrat;
	font-size: 30px;
	width: 707px;
	text-align: center;
	font-weight: normal;
}

.shop-now {
	min-height: 68px;
	background-color: #ffcb00;
	border-radius: 100px;
	padding: 8px 32px;
	text-transform: uppercase;
	margin-right: 24px;
	cursor: pointer;
	transition: All 0.5s ease;
	flex-direction: column;
	justify-content: center;
}

.shop-now:hover {
	box-shadow: 0 10px 50px 0px rgb(255 203 0 / 60%);
}

.shop-now .text {
	color: #000000;
	font-family: Montserrat;
	font-size: 24px;
	font-weight: 700;
}

.shop-now .price {
	margin-left: 24px;
}

.shop-now .price .unit {
	color: #000000;
	font-family: Montserrat;
	font-size: 32px;
	font-weight: 700;
}

.shop-now .price .shipping {
	color: #000000;
	font-family: Montserrat;
	font-size: 10px;
}

.play-video {
	background-color: #2f2d47;
	border-radius: 100px;
	padding: 8px 32px;
	text-transform: uppercase;
	margin-right: 24px;
	cursor: pointer;
	min-height: 68px;
	transition: All 0.5s ease;
	box-sizing: border-box;
}

.play-video:hover {
	box-shadow: 0 10px 50px 0 rgb(33 150 243 / 50%);
}

.play-video .text {
	color: #ffffff;
	font-family: Montserrat;
	font-size: 16px;
	margin-left: 16px;
}

.play-video .icon {
}

.divider {
	border: 12px solid #5baaff59;
	margin: 0 -64px;
	height: 0;
	border-style: dashed;
	transform: skewX(-40deg);
}

@keyframes fading {
	from {
		opacity: 0.4;
		transform: scale(1);
	}

	to {
		opacity: 0.8;
		transform: scale(1.05);
	}
}
