.loader-overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgb(47, 45, 71, 0.9);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	animation: fade 0.4s ease;
}

.loader-overlay .loader-text {
	text-align: center;
	color: #ffffff;
	font-size: 14px;
	margin-top: 24px;
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 100%;
	}
}
