[disabled] {
	pointer-events: none;
	opacity: 0.3;
}
.overlay {
	background: rgba(47, 45, 71, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	transition: All 0.4s ease;
}

.modal {
	border-radius: 24px;
	border: none;
	background: white;
}

.modal .notice {
	font-size: 10px;
	background: #fffadd;
	padding: 8px 16px;
	margin-bottom: 16px;
	border-radius: 8px;
	font-weight: 700;
}

.modal .title {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 24px;
}

.input {
	margin-bottom: 16px;
	text-align: left;
}

.input .label {
	font-size: 10px;

	margin-bottom: 4px;
}

.input .field > span {
	color: #e91e63;
	font-size: 10px;
	margin-top: 4px;
}

.input input {
	font-family: Montserrat;
	border-radius: 10px;
	border: none;
	background: #e7f1fe;
	padding: 16px;
	outline: none;
}
.input select {
	font-family: Montserrat;
	border-radius: 10px;
	border: none;
	background: #e7f1fe;
	padding: 16px;
	outline: none;
}

.input textarea {
	font-family: Montserrat;
	border-radius: 10px;
	border: none;
	background: #e7f1fe;
	padding: 16px;
	outline: none;
}

.input .increment {
	width: 48px;
	height: 48px;
	text-align: center;
	line-height: 48px;
	background: #ffcb00;
	border-radius: 50%;
	font-weight: 700;
	font-size: 24px;
	cursor: pointer;
	outline: none;
}

.input .increment:hover {
	transform: scale(1.05);
}

.input .qte {
	margin: 0 16px;
	font-weight: 800;
	font-size: 18px;
	text-align: center;
}

.resume {
	margin-top: 24px;
}
.resume .line {
	font-size: 14px;
	padding: 7px 0;
	border-top: 1px solid #e3e3e3;
}

.resume .line:last-child {
	font-weight: 700;
	font-size: 18px;
	padding-top: 24px;
}

.action {
	margin-top: 24px;
}
.action button {
	height: 48px;
	font-family: Montserrat;
	cursor: pointer;
	border: none;
	border-radius: 70px;
	padding: 16px;
}

.action .primary {
	background: #ffcb00;
}

.action .order {
	margin-right: 16px;
	font-weight: 700;
}

.point-retrait {
	max-width: 550px;
	background: #f3f9fd;
	border-radius: 8px;
	border: 7px dashed #5baaffb8;
	margin-bottom: 24px;
	padding: 16px;
}

.point-retrait .img {
	width: 128px;
	min-width: 128px;
	max-width: 128px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.row-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.row-col {
	display: flex;
	flex-direction: column;
}
.point-retrait img {
	width: 100%;
	display: block;
	border-radius: 16px;
}

.point-retrait .location-detail {
	flex: 1 1;
	padding-left: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.point-retrait .location-detail h1 {
	font-size: 16px;
	margin: 0;
}

.point-retrait .location-detail .addr {
	font-size: 14px;
	margin: 16px 0;
}

.point-retrait .location-detail .loc {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #009688;
}
.point-retrait .loc {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
}

.radios {
	display: flex;
	gap: 16px;
}
.radios .radio {
	padding: 16px;
	border-radius: 4px;
	flex: 1;
	border: 4px solid #def0ff;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: All 0.3s ease-in-out;
}

.radios .radio:hover {
	border-color: #ffcb00;
}

.radios .radio span {
	flex: 1;
}

.radios .radio input {
	margin: 0;
	width: 24px;
	height: 24px;
	margin-right: 16px;
}

.radios .radio input[checked] {
	opacity: 1;
}

.icon.checkmark-fail {
	width: 154px;
	height: 154px;
	background-color: red;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 80px;
	text-align: center;
	border-radius: 50%;
	margin: 0 auto;
}

.fail-order {
	text-align: center;
}

.success-order h1 {
	font-family: "Montserrat";
	font-size: 24px;
	margin: 0 auto;
	padding: 24px 0;
	text-align: center;
	max-width: 60%;
}

.whatsapp-btn {
	background-color: #009688;
	background: #009688;
	color: #ffffff;
	margin-bottom: 12px;
}
.icon {
	margin-left: 10%; /* Space between text and icon */
}
