.order-list {
	height: 100vh;
	box-shadow: 0 0 30px 0 black;
	display: flex;
	flex-direction: column;
}

.order-list .order-details {
	flex: 1;
	background: #f1f7f8;
}

.order-list .order-details .bloc {
	text-align: left;
	flex: 1;
	margin-right: 10px;
}

.order-list .order-details .bloc .libelle {
	font-size: 10px;
	font-weight: 500;
	color: #03a9f4;
	margin-bottom: 4px;
}

.order-line {
	background-color: white;
	display: flex;
	align-items: flex-start;
	padding: 32px;
	font-family: Montserrat;
	font-size: 14px;
	flex: 1;
	min-height: 54px;
}

.order-list .headers {
	font-weight: bold;
	background-color: white;
	display: flex;
	align-items: center;
	padding: 8px 32px;
	font-family: Montserrat;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	min-height: 54px;
	height: 54px;
}

.order-list .headers .item {
	font-size: 12px;
}

.order-line .item {
	text-align: left;
	flex: 1;
}

.order-list-title {
	font-size: 18px;
	font-family: Montserrat;
	text-align: left;
	margin: 0;
	padding: 60px 32px 60px 32px;
	flex: 1;
	color: #ffc107;
}

.CA {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 24px;
	color: #ffc107;
}

.CA .lbl {
	font-size: 14px;
}

.CA .count {
	font-size: 40px;
	font-weight: 900;
}

.order-line-container {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ddd;
	margin-bottom: 8px;
}
.order-line-container .status {
	font-size: 10px;
	font-weight: 700;
	padding: 8px 16px;
	border-radius: 26px;
	text-align: center;
}

.order-line-container .status.new {
	background: #ffc107;
	cursor: pointer;
}
.order-line-container .status.shipped {
	background: #5baaff;
}

.order-line-container .status.cancelled {
	background: #dddddd;
}

.react-tabs__tab-list {
	margin: 0 !important;
}

.react-tabs__tab {
	padding: 16px 24px !important;
	color: white;
}

.react-tabs__tab--selected {
	color: black !important;
}
