@media screen and (max-width: 768px) {
	.row {
		flex-direction: column !important;
	}

	.header {
		flex-direction: column;
		margin-top: 16px;
	}

	.social {
		flex-direction: row !important;
		justify-content: center !important;
	}

	.container {
		display: block !important;
		height: auto !important;
		padding: 0 40px !important;
	}

	.p-dialog {
		width: 100% !important;
		min-height: 100% !important;
	}

	.show-video .p-dialog-content > div {
		width: 100% !important;
	}

	.container .logo {
		width: 100% !important;
		margin: 0 !important;
		text-align: center !important;
	}
	.container .bg {
		position: initial !important;
		width: 100% !important;
		margin: 48px 0 !important;
		z-index: 1 !important;
	}
	.container .description {
		width: 100% !important;
		padding: 0 !important;
		font-size: 24px !important;
		line-height: normal !important;
		text-align: center !important;
		margin-bottom: 24px !important;
	}
	.shop-now {
		margin: 24px 0 !important;
		width: 100% !important;
	}

	.shop-now .price {
		margin-left: 0 !important;
	}

	.play-video {
		align-items: center !important;
		margin: 0 0 24px 0 !important;
		width: 100% !important;
		flex-direction: row !important;
		align-items: center !important;
	}
	.play-video .text {
		text-align: center !important;
		margin: 0 !important;
		margin-left: 18px !important;
	}
	.features .feature {
		margin-bottom: 48px !important;
	}
	.features .feature .picto {
		margin-bottom: 8px !important;
	}

	.input .numericupdown {
		flex-direction: row !important;
	}

	dialog {
		padding: 16px !important;
	}

	.action .order {
		margin-right: 0 !important;
	}

	.action button {
		margin-bottom: 16px !important;
	}

	.order-line-container {
		padding: 16px !important;
	}
	.order-list .headers {
		display: none !important;
	}

	.order-line {
		flex-direction: column !important;
		overflow: hidden;
		padding: 0 !important;
		height: auto !important;
		align-items: normal !important;
		box-shadow: 0 0 10px 0 #0000002b !important;
		border-radius: 12px !important;
	}

	.order-line .item.reduce {
		max-width: 100% !important;
	}

	.order-line .item {
		margin: 4px 0 !important;
		font-size: 14px !important;
		flex: 0 !important;
		min-width: 60%;
	}

	.order-list .order-details .bloc {
		border-bottom: 1px solid #e5e5e5 !important;
		padding: 16px !important;
		margin: 0 !important;
		display: flex;
		align-items: center;
	}

	.order-list .order-details .bloc:nth-child(odd) {
		background: #fafafa;
	}

	.order-list .order-details .bloc .libelle {
		flex: 1;
		margin-bottom: 0 !important;
	}

	.order-list-title {
		padding: 16px !important;
		background: rgb(0 0 0 / 35%);
	}
}
